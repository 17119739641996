import LogoS from '../../../assets/images/SiteLogo.png'
import './index.scss'
import { motion } from 'framer-motion'

const svgVariants = {
  hidden: {
    rotate: -180,
  },
  visible: {
    rotate: 0,
    transition: {
      duration: 1,
    },
  },
}

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      delay: 2,
      duration: 2,
      ease: 'easeInOut',
    },
  },
}

const Logo = () => {
  return (
    <div className="logo-container">
      <img className="solid-logo" src={LogoS} alt="D" />
      <motion.svg
        width="500"
        zoomAndPan="magnify"
        viewBox="0 0 375 374.999991"
        height="500"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
        initial="hidden"
        animate="visible"
        variants={svgVariants}
      >
        <g className="svg-container" fill="none">
          <motion.path
            variants={pathVariants}
            d="M 22.574219 375 L 187.351562 375 C 238.800781 375 285.75 354 319.875 319.875 C 353.699219 285.75 375 239.101562 375 187.351562 C 375 135.898438 353.699219 88.949219 319.875 55.125 C 285.75 21 238.800781 0 187.351562 0 L 112.726562 0 L 112.726562 45.074219 L 187.351562 45.074219 C 226.648438 45.074219 261.976562 61.199219 287.851562 86.773438 C 313.726562 112.648438 329.550781 148.273438 329.550781 187.273438 C 329.550781 226.574219 313.726562 261.898438 287.851562 287.773438 C 261.976562 313.648438 226.648438 329.851562 187.351562 329.851562 L 45.074219 329.851562 L 45.074219 112.726562 L 0 112.726562 L 0 375 Z M 22.574219 375 "
          />
          <motion.path
            variants={pathVariants}
            d="M 157.800781 216.898438 L 157.800781 158.101562 L 187.351562 158.101562 C 195.300781 158.101562 202.574219 161.476562 208.050781 166.648438 C 213.523438 172.125 216.898438 179.476562 216.898438 187.351562 C 216.898438 195.226562 213.523438 202.875 208.050781 208.050781 C 202.574219 213.523438 195.226562 216.898438 187.351562 216.898438 Z M 112.726562 135.226562 L 112.726562 261.976562 L 187.351562 261.976562 C 207.75 261.976562 226.351562 253.425781 240.074219 240.074219 C 253.5 226.648438 261.976562 207.75 261.976562 187.351562 C 261.976562 166.949219 253.425781 148.351562 240.074219 134.925781 C 226.351562 121.199219 207.75 112.648438 187.351562 112.648438 L 112.726562 112.648438 Z M 112.726562 135.226562 "
          />
          <motion.path
            variants={pathVariants}
            d="M 12.523438 112.726562 L 158.175781 112.726562 L 158.175781 157.800781 L 12.523438 157.800781 Z M 12.523438 112.726562 "
          />
          <motion.path
            variants={pathVariants}
            d="M 112.726562 160.574219 L 112.726562 112.726562 L 157.800781 112.726562 L 157.800781 160.574219 Z M 112.726562 90.148438 L 112.726562 14.925781 L 157.800781 14.925781 L 157.800781 90.148438 Z M 112.726562 90.148438 "
          />
          <motion.path
            variants={pathVariants}
            d="M 0 0 L 90.148438 0 L 90.148438 90.148438 L 0 90.148438 Z M 0 0 "
          />
        </g>
      </motion.svg>
    </div>
  )
}

export default Logo
