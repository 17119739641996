import { useEffect, useState } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

import AboutPic from '../../assets/images/AboutPic.jpg'
import Cubespinner from './Cubespinner'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 2000)

    return () => clearTimeout(timeout) // Clear the timeout on unmount
  }, [])

  let aboutme = 'A b o u t  M e'
  const aboutArray = aboutme.split(' ')

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={aboutArray}
              idx={15}
            />
          </h1>
          <div className="scrollable-content">
            <div className="avatar-container">
              <img src={AboutPic} alt="profilepicture" className="about-pic" />
            </div>
            <p>
            With 5 years of experience, I am proficient in both frontend and backend technologies. I excel in developing applications using React and Angular for the frontend. On the backend, I have expertise in Java, Python, and JavaScript frameworks such as NestJS and Node.js. I am well-versed in working with databases including MySQL, PostgreSQL, and MongoDB. Additionally, I have extensive experience in PHP and MySQL, making me highly skilled in Wordpress Theme and Plugin Development.
            </p>
            <Cubespinner />
          </div>
        </div>

        <div className="stage-cube-cont mobile-hidden">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="square-spin" />
    </>
  )
}

export default About
