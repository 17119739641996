import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import TextSphere from '../TextSphere'
import MobileTextSphere from '../MobileTextSphere'
import TickerCarousel from '../TickerCarousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngular,
  faAws,
  faCloudflare,
  faCss3,
  faDocker,
  faGit,
  faHtml5,
  faJava,
  faJenkins,
  faJira,
  faJs,
  faJsSquare,
  faLinux,
  faPhp,
  faPython,
  faReact,
  faSalesforce,
  faTrello,
  faWordpress,
} from '@fortawesome/free-brands-svg-icons'

const Skills = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3500)

    return () => clearTimeout(timeout) // Clear the timeout on unmount
  }, [])

  let mySkills = 'S k i l l s'
  const skillsArray = mySkills.split(' ')

  return (
    <>
      <div className="container skills-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={skillsArray}
              idx={35}
            />
          </h1>
          <div className="scrollable-content">
            <p>
              Experienced Full Stack Developer with a Passion for Frontend
              Excellence 🚀: Bringing creativity and precision to web
              development, I specialize in crafting immersive user experiences
              through cutting-edge frontend technologies.
            </p>
            <div className="skill-ticker">
              <TickerCarousel />
            </div>
            <div className="tech-stack">
              <div class="stack-container">
                <div className="frontend-stack">
                  <h2>Frontend Technologies</h2>
                  <div className="technologies">
                    <FontAwesomeIcon icon={faReact} />
                    <FontAwesomeIcon icon={faAngular} />
                    <FontAwesomeIcon icon={faJsSquare} />
                    <FontAwesomeIcon icon={faHtml5} />
                    <FontAwesomeIcon icon={faCss3} />
                  </div>
                </div>
                <div className="backend-stack">
                  <h2>Backend Technologies</h2>
                  <div className="technologies">
                    <FontAwesomeIcon icon={faPython} />
                    <FontAwesomeIcon icon={faPhp} />
                    <FontAwesomeIcon icon={faJava} />
                    <FontAwesomeIcon icon={faWordpress} />
                  </div>
                </div>
                <div className="devops-stack">
                  <h2>DevOps Technologies</h2>
                  <div className="technologies">
                    <FontAwesomeIcon icon={faDocker} />
                    <FontAwesomeIcon icon={faAws} />
                    <FontAwesomeIcon icon={faCloudflare} />
                    <FontAwesomeIcon icon={faJenkins} />
                  </div>
                </div>
                <div className="cms-stack">
                  <h2>Productivity and Other Tools</h2>
                  <div className="technologies">
                    <FontAwesomeIcon icon={faJira} />
                    <FontAwesomeIcon icon={faTrello} />
                    <FontAwesomeIcon icon={faSalesforce} />
                    <FontAwesomeIcon icon={faLinux} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-skill-sphere show-on-mobile">
              <MobileTextSphere />
            </div>
          </div>
        </div>
        <div className="skill-sphere mobile-hidden">
          <TextSphere />
        </div>
      </div>
      <Loader type="square-spin" />
    </>
  )
}

export default Skills
