import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import './index.scss'

const MapLayout = () => {
  return (
    <>
      <div class="mobile-map-container">
          <div className="info-map">
            Daniel Romano,
            <br />
            Philippines,
            <br />
            Cebu City, Cebu, 6000
            <br />
            <a href="mailto:danny.aics@gmail.com">
              <span>danny.aics@gmail.com</span>
            </a>
            <span>+63 991-219-7007</span>
          </div>
          <div className="map-wrap">
            <MapContainer center={[10.3157, 123.8854]} zoom={13}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[10.3157, 123.8854]}>
                <Popup>I live here, come hang out with me</Popup>
              </Marker>
            </MapContainer>
          </div>
      </div>
    </>
  )
}

export default MapLayout
