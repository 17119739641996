import { useState } from 'react'
import { Link } from 'react-router-dom'
import LogoTitle from '../../assets/images/SiteLogo.png'
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import './index.scss'
import Loader from 'react-loaders'
import MyResume from '../../assets/MyResume.pdf'

const Home = () => {
  const [letterClass] = useState('text-animate')
  let hi = 'H i ,'.split(' ')
  let im = "I' m".split(' ')
  let name = 'a n n y ,'.split(' ')
  let job = 'W e b  D e v e l o p e r'.split(' ')

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={hi}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={im}
            />
            <img src={LogoTitle} alt="Dev" />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={name}
            />
            <br />
            <span className="jobtitle">
              <AnimatedLetters
                letterClass={letterClass}
                strArray={job}
                idx={1}
              />
            </span>
          </h1>
          <h2 className="text-with-outline">
            Full Stack Developer / WordPress Developer / Web Designer / SEO Specialist
          </h2>
          
          <div class="flat-button-row">
            <Link to="/contact" className="flat-button">
              CONTACT ME
            </Link>
            <a href={MyResume} target='_blank' rel='noreferrer' className='flat-button'>
              Download Resume
            </a>
          </div>
        </div>
        <Logo />
      </div>
      <Loader type="square-spin" />
    </>
  )
}

export default Home
