import { motion, useAnimationControls } from 'framer-motion'
import './index.scss'
import { useState } from 'react'

const AnimatedLetter = ({ letterClass, char, idx }) => {
  const controls = useAnimationControls()
  const [isPlaying, setIsPlaying] = useState()

  const rubberBand = () => {
    controls.start({
      transform: [
        'scale3d(1, 1, 1)',
        'scale3d(1.4, .55, 1)',
        'scale3d(.75, 1.25, 1)',
        'scale3d(1.25, .85, 1)',
        'scale3d(.9, 1.05, 1)',
        'scale3d(1, 1, 1)',
      ],
      transition: {
        times: [0, 0.4, 0.6, 0.7, 0.8, 0.9],
        duration: .8
      },
    })
    setIsPlaying(true)
  }

  return (
    <motion.span
      animate={controls}
      onMouseOver={() => {
        if (!isPlaying) rubberBand()
      }}
      onAnimationComplete={() => setIsPlaying(false)}
      className={`${letterClass} _${idx}`}
    >
      {char}
    </motion.span>
  )
}

const AnimatedLetters = ({ letterClass, strArray }) => {
  return (
    <span>
      {strArray.map((char, i) => (
        <AnimatedLetter key={i} letterClass={letterClass} char={char} idx={i} />
      ))}
    </span>
  )
}

export default AnimatedLetters
