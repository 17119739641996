import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MapLayout from '../MapLayout'

const Contact = () => {
  let contactme = 'C o n t a c t  M e'
  const contactArray = contactme.split(' ')

  const [letterClass, setLetterClass] = useState('text-animate')

  const refForm = useRef()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    return () => clearTimeout(timeout)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_knz9aca',
        'template_73ye11d',
        refForm.current,
        'x7kt9igtLKiRE45Hw'
      )
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to Send')
        }
      )
  }

  return (
    <>
      <div className="container contact-page ">
        <div className="text-zone">
          <div class="scrollable-contact-content">
            <h1>
              <AnimatedLetters
                strArray={contactArray}
                idx={15}
                letterClass={letterClass}
              />
            </h1>
            <p>
              I'm excited to connect with you! Whether you have a question, a
              project idea, or just want to say hi, feel free to reach out using
              the form below. Your message is important to me, and I'll get back
              to you as soon as possible.
            </p>
            <p>Let's bring your web vision to life together!</p>
            <div className="contact-form">
              <form ref={refForm} onSubmit={sendEmail}>
                <ul>
                  <li className="half">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </li>
                  <li className="half">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </li>
                  <li>
                    <input
                      placeholder="Subject"
                      type="text"
                      name="subject"
                      required
                    />
                  </li>
                  <li>
                    <textarea placeholder="Message" name="message" required />
                  </li>
                  <li>
                    <input type="submit" className="flat-button" value="Send" />
                  </li>
                </ul>
              </form>
            </div>
            <MapLayout />
          </div>
        </div>
        <div className="info-map mobile-hidden">
          Daniel Romano,
          <br />
          Philippines,
          <br />
          Cebu City, Cebu, 6000
          <br />
          <a href="mailto:danny.aics@gmail.com">
            <span>danny.aics@gmail.com</span>
          </a>
          <span>+63 991-219-7007</span>
        </div>
        <div className="map-wrap mobile-hidden">
          <MapContainer center={[10.3157, 123.8854]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[10.3157, 123.8854]}>
              <Popup>I live here, come hang out with me</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="square-spin" />
    </>
  )
}

export default Contact
