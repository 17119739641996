import './index.scss'
import { Link, NavLink } from 'react-router-dom'

import LogoS from '../../assets/images/SiteLogo.png'
import LogoSubtitle from '../../assets/images/subtitle.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faEnvelope,
  faHome,
  faUser,
  faWrench,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSkype,
} from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'

const Sidebar = () => {
  const [navi, setNav] = useState(false)
  const handleNav = () => {
    setNav(!navi)
  }

  const navIcon = navi ? faX : faBars

  return (
    <div className="nav-bar">
      <Link className="logo" to="/">
        <img src={LogoS} alt="Logo" />
        <img className="sub-logo" src={LogoSubtitle} alt="Daniel Romano Logo" />
      </Link>
      <nav>
        <NavLink exact="true" activeclassname="active" to="/">
          <FontAwesomeIcon icon={faHome} color="#ebf3ff" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="about-link"
          to="/about"
        >
          <FontAwesomeIcon icon={faUser} color="#ebf3ff" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="skills-link"
          to="/skills"
        >
          <FontAwesomeIcon icon={faWrench} color="#ebf3ff" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="contact-link"
          to="/contact"
        >
          <FontAwesomeIcon icon={faEnvelope} color="#ebf3ff" />
        </NavLink>
      </nav>
      <FontAwesomeIcon
        onClick={handleNav}
        icon={navIcon}
        color="#ebf3ff"
        className={`nav-menu ${navi ? 'active' : ''}`}
      />
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/daniel-romano-13a632239/"
          >
            <FontAwesomeIcon icon={faLinkedin} color="#ebf3ff" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://join.skype.com/invite/xjljIQi9whfr"
          >
            <FontAwesomeIcon icon={faSkype} color="#ebf3ff" />
          </a>
        </li>
      </ul>

      {navi ? (
        <div className="nav-wrapper">
          <NavLink
            exact="true"
            activeclassname="active"
            to="/"
            onClick={handleNav}
          >
            <div class="nav-menu-item">
              <FontAwesomeIcon icon={faHome} color="#ebf3ff" />
              <span>Home</span>
            </div>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="about-link"
            to="/about"
            onClick={handleNav}
          >
            <div class="nav-menu-item">
              <FontAwesomeIcon icon={faUser} color="#ebf3ff" />
              <span>About</span>
            </div>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="skills-link"
            to="/skills"
            onClick={handleNav}
          >
            <div class="nav-menu-item">
              <FontAwesomeIcon icon={faWrench} color="#ebf3ff" />
              <span>Skills</span>
            </div>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="contact-link"
            to="/contact"
            onClick={handleNav}
          >
            <div class="nav-menu-item">
              <FontAwesomeIcon icon={faEnvelope} color="#ebf3ff" />
              <span>Contact</span>
            </div>
          </NavLink>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Sidebar
