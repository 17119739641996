import TagCloud from "TagCloud";
import { useEffect, useRef } from "react";

const MobileTextSphere = () => {
  const tagCloudContainerRef = useRef(null);

  useEffect(() => {
    const container = tagCloudContainerRef.current;
    const texts = [
      "HTML5",
      "CSS",
      "JavaScript",
      "PHP",
      "MySQL",
      "Angular",
      "React",
      "Java",
      "Git",
      "Figma",
      "WordPress",
      "SASS",
      "ADOBE XD",
      "GITLAB",
    ];

    const options = {
      radius: 200,
      maxSpeed: 'fast',
      initSpeed: 'normal',
      keep: true,
    };

    TagCloud(container, texts, options);

    return () => {
      // Clean up the previous TagCloud instance
      container.innerHTML = "";
    };
  }, []);

  return (
    <>
      <span ref={tagCloudContainerRef} className="tagcloud"></span>
    </>
  );
};

export default MobileTextSphere;
