import {
  faAngular,
  faCss3,
  faHtml5,
  faJsSquare,
  faPhp,
  faReact,
  faWordpress,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Ticker from 'react-ticker'
import './index.scss'

const TickerCarousel = () => {
  return (
    <Ticker mode="chain" speed={10} offset={30}>
      {({ index }) => (
        <>
          <FontAwesomeIcon icon={faHtml5} color="#FF5733" />
          <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
          <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
          <FontAwesomeIcon icon={faPhp} color="#474a8a" />
          <FontAwesomeIcon icon={faReact} color="#7cc5d9" />
          <FontAwesomeIcon icon={faAngular} color="#DD0031" />
          <FontAwesomeIcon icon={faWordpress} color="#07749C" />
        </>
      )}
    </Ticker>
  )
}

export default TickerCarousel
